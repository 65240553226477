import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';

const NikkeGuidesGamemodesInterceptionPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Interception</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_interception.png"
            alt="Interception"
          />
        </div>
        <div className="page-details">
          <h1>Interception</h1>
          <h2>Guide for the Interception mode in NIKKE.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Interception" />
        <p>
          Interception is a mode where you have to attack a boss and receive
          rewards based on the damage done. At first only one boss will be
          available and you will need to progress further in the Campaign to
          unlock the other ones - which are harder but in return drop better
          rewards.
        </p>
        <p>
          You unlock this mode after clearing Stage 3-9 and it's the main source
          of equipment in NIKKE.
        </p>
        <SectionHeader title="Rewards" />
        <StaticImage
          src="../../../images/nikke/generic/inter_rewards.jpg"
          alt="Interception"
        />
        <p>
          While fighting the boss, the stage meter will slowly fill based on the
          damage done. There are in total 9 stages per boss and the rewards
          increase with them (shown on the image above).
        </p>
        <p>
          You get only 3 challenges per day, so make them count to maximize the
          amount of rewards.
        </p>
        <SectionHeader title="Bosses" />
        <p>
          Currently there are in total 3 bosses available in the Interception
          mode.
        </p>
        <h5>Alteisen MK.VI</h5>
        <StaticImage
          src="../../../images/nikke/generic/boss_d.jpg"
          alt="Interception"
        />
        <p>
          If you played the CBT you should be familiar with the boss as it was
          available in the Coop event. It's the boss that you will fight right
          after unlocking the mode.
        </p>
        <h5>Gravedigger</h5>
        <StaticImage
          src="../../../images/nikke/generic/boss_s.jpg"
          alt="Interception"
        />
        <p>You will unlock this boss after clearing stage 9-15.</p>
        <p>
          Shotgun team shines on this boss as it's really hard to hit all the
          red circles with non-Shotgun characters.
        </p>
        <h5>Special</h5>
        <StaticImage
          src="../../../images/nikke/generic/boss_b.jpg"
          alt="Interception"
        />
        <p>
          You will unlock the Special boss after clearing stage 16-28, but
          compared to the previous two that never change, the boss you fight
          here changes every day.
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default NikkeGuidesGamemodesInterceptionPage;

export const Head: React.FC = () => (
  <Seo
    title="Interception | NIKKE | Prydwen Institute"
    description="Guide for the Interception mode in Goddess of Victory: NIKKE."
  />
);
